const LOAD_BUTTON_CLASS = "infinite-load__button";
const FORM_LOADING_CLASS = "marketing-form--is-loading";

class InfiniteLoad {
  constructor() {
    this.handleClick = this.handleClick.bind(this);
    document.body.addEventListener('click', this.handleClick);
  }

  async loadContent(url, parentContainer) {
    const resultsContainer = parentContainer;
    resultsContainer.classList.add(FORM_LOADING_CLASS);

    try {
      const content = await this.fetchContent(url);
      resultsContainer.innerHTML = content;
    } catch (error) {
      window.location.assign(url);
    }

    resultsContainer.classList.remove(FORM_LOADING_CLASS);
  }

  async handleClick(evt) {
    if (!evt.target.classList.contains(LOAD_BUTTON_CLASS)) {
      return;
    }
    const button = evt.target;
    const url = button.getAttribute('data-url');
    const parentContainer = button.closest('.infinite-load__container');

    await this.loadContent(url, parentContainer);
  }

  async fetchContent(url) {
    const response = await fetch(url, {
      headers: {
        'Content-type': 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    return response.text();
  }
}

export default InfiniteLoad;
