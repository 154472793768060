import {Carousel, SocialShareButton} from '@shopify/marketing-assets';

import InfiniteLoad from '../modules/success-stories/infinite-load';
import AjaxNavigation from '../modules/success-stories/ajax-navigation';
import '../components/storename-autogen-signup-smart-field';

(function() {
  const carousel = document.querySelector('.success-stories-carousel');
  const selectElement = document.getElementById('SuccessStoriesFilter');

  if (selectElement) {
    new AjaxNavigation();
  }

  new InfiniteLoad();

  if (carousel) {
    new Carousel(carousel);
  }

  document.querySelectorAll('.js-social-share').forEach((el) => new SocialShareButton(el));
})();
