class AjaxNavigation {
  constructor() {
    this.selectElement = document.getElementById('SuccessStoriesFilter');
    this.body = document.getElementById('SuccessStoriesBody');
    this.cache = {};
    this.path = '';

    this.url = this.selectElement.value;
    this.html = this.body.innerHTML;
    this.selectElement.addEventListener('change', this.onSelectChange.bind(this));

    if (window.history.pushState) {
      window.onpopstate = this.onPopState.bind(this);
      history.replaceState({
        id: this.url,
        html: this.html,
      }, 'start', document.location.href);
    }
  }

  onSelectChange() {
    this.url = this.selectElement.value;
    this.body.classList.add('js-is-loading');
    this.load();
    this.parameterize();
  }

  onPopState(evt) {
    this.html = evt.state.html;
    this.url = evt.state.id;
    this.load();
    this.updateSelected();
  }

  async load() {
    const url = this.url;

    await this.loadContent(url);
  }

  async loadContent(url) {
    const cacheContent = this.retrieveFromCache() || null;

    if (cacheContent) {
      this.html = cacheContent;
      this.render(cacheContent);
    } else {
      try {
        const content = await this.fetchContent(url);

        this.render(content);
      } catch (error) {
        window.location = this.url;
      }
    }

    this.body.classList.remove('js-is-loading');
  }

  async fetchContent(url) {
    const response = await fetch(url, {
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });

    return response.text();
  }

  render(content) {
    this.body.innerHTML = content;
    this.body.querySelectorAll('a')[0].focus();
    this.updateState();
    this.updateCache();
  }

  parameterize() {
    const arr = this.url.split('/');

    if (arr.length > 2) {
      const id = arr[arr.length - 1];
      const cat = arr[arr.length - 2];
      this.path = `?${cat}=${id}`;
    } else {
      this.path = '?';
    }
  }

  updateState() {
    if (!window.history.pushState) {
      return;
    }

    this.parameterize();
    history.pushState({
      id: this.url,
      html: this.html,
    }, this.url, this.path);
  }

  retrieveFromCache() {
    return this.cache[this.url];
  }

  updateCache() {
    this.cache[this.url] = this.body.innerHTML;
  }

  updateSelected() {
    this.selectElement.value(this.url);
  }
}

export default AjaxNavigation;
